<template>
  <main class="auth-page" id="auth-page">
    <form @submit.prevent="submit" class="auth-form">
      <h1 class="auth-title">Sign In</h1>
      <AInput
        label="Login"
        name="login"
        placeholder="Enter login"
        v-model.trim="$v.login.$model"
        autocomplete="on"
        :error="errorLogin"
      />
      <AInput label="Password" placeholder="Enter password"
              v-model.trim="$v.password.$model"
              :error="$v.password.$error ? 'Required' : null"
              autocomplete="on" name="password" type="password"/>
      <button class="btn blue" :disabled="$v.$invalid"  type="submit">Log in</button>
<!--      <router-link to="/sign-up" class="auth-create">Create Account</router-link>-->
    </form>
  </main>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    login: {
      required
    },
    password: {
      required
    }
  },
  components: {
    AInput: () => import('@/components/common/AInput')
  },
  data() {
    return {
      login: '',
      password: '',
      pending: false
    }
  },
  computed: {
    ...mapGetters(['isLogged']),
    errorLogin() {
      if(this.$v.login.$error) {
        if (!this.$v.login.required) {
          return 'Required'
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions({loginAction: 'login'}),
    async submit() {
      await this.loginAction({
          email: this.login,
          password: this.password
        }).then(() => {
        this.pending = false;
      }).catch(error => {
        console.error('Error login:', error);
        this.$notify({
          title: 'Login',
          text: error.response && error.response.data.message,
          type: 'error'
        });
      })
    }
  },
  created() {
    if(this.isLogged) {
      this.$router.push({name: 'Articles'});
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/styles/views/auth";
</style>
